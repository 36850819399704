<template>
  <div v-if="!user || !user.id">
    <!-- loading -->
  </div>
  <p-form
    v-else-if="user.hasPassword"
    @submit="onSubmit"
    v-slot="{ invalid }"
  >
    <div class="c-row c-horizontal-center">
      <div class="c-col-2 sm:c-col-6 md:c-col-12 lg:c-col-10">
        <!-- Título "Cadastre sua nova senha" e subtítulo -->
        <div class="c-row ui-mb-4">
          <div class="c-col-full">
            <!-- Título -->
            <div class="c-text-h3">
              Cadastre sua nova senha
            </div>

            <!-- Subtítulo -->
            <div class="c-text-b2 c-text-gray">
              *Preenchimento obrigatório
            </div>
          </div>
        </div>

        <!-- Senha atual -->
        <div class="c-row ui-mb-4">
          <validation-provider
            class="c-col-full sm:c-col-1/2"
            rules="required"
            name="Senha atual"
            v-slot="{ errors }"
            :custom-messages="{ required: 'Senha atual é obrigatória' }"
          >
            <input-password
              data-cy="currentPasswordField"
              label="Senha atual*"
              v-model="form.currentPassword"
              :error-message="errors[0]"
            />
            <div class="c-d-flex ui-items-center ui-mt-1 sm:c-d-none">
              <icon
                name="info"
                color="var(--c-color-gray-300)"
                class="ui-w-3 ui-mr-1"
                filled
              />
              <span class="c-text-b3 c-text-gray-300">
                Valide sua conta
              </span>
            </div>
          </validation-provider>
        </div>

        <separator class="sm:c-d-none" />

        <!-- Nova senha e Confirmar senha -->
        <div class="c-row">
          <!-- Nova senha -->
          <validation-provider
            class="c-col-full sm:c-col"
            rules="required|min:5"
            vid="password"
            name="Senha"
            v-slot="{ errors }"
            :custom-messages="{
              required: 'Senha é obrigatória',
              min: 'A senha é muito curta'
            }"
          >
            <input-password
              data-cy="newPasswordField"
              label="Nova Senha*"
              v-model="form.newPassword"
              icon-info="info"
              :error-message="errors[0]"
              class="ui-mb-4"
            />
          </validation-provider>

          <!-- Confirmar senha -->
          <validation-provider
            class="c-col-full sm:c-col"
            rules="required|confirmed:password"
            name="Confirmação de senha"
            v-slot="{ errors }"
            :custom-messages="{
              required: 'Confirmação de senha é obrigatória',
              confirmed: 'Senhas não são iguais'
            }"
          >
            <input-password
              label="Confirmar nova senha*"
              v-model="form.confirmPassword"
              :error-message="errors[0]"
            />
          </validation-provider>
        </div>

        <div
          v-if="errors.length > 0"
          class="q-mt-md text-negative"
        >
          <q-list>
            <div
              v-for="(error, index) in errors"
              :key="index"
            >
              * {{ error }}
            </div>
          </q-list>
        </div>
        <separator />
      </div>
    </div>

    <!-- Botão Alterar e Texto Cancelar -->
    <div class="c-row c-horizontal-center">
      <!-- Botão Alterar -->
      <div class="c-col-full sm:c-col-1/4 lg:c-col-2">
        <c-button
          data-cy="updatePasswordSubmitButton"
          type="submit"
          :disabled="invalid"
          :loading="isSubmitting"
          class="ui-w-full ui-mb-4 sm:ui-mb-0"
        >
          Salvar Alteração
        </c-button>
      </div>

      <!-- Botão Cancelar -->
      <div class="c-col-full sm:c-col-1/4 lg:c-col-2">
        <router-link
          :to="{ name: 'meus-dados' }"
        >
          <c-button
            outline
            :disabled="isSubmitting"
            class="ui-w-full"
          >
            Voltar sem salvar
          </c-button>
        </router-link>
      </div>
    </div>
  </p-form>
  <div
    v-else
    class="c-row c-horizontal-center"
  >
    <div class="c-col-2 sm:c-col-6 md:c-col-12 lg:c-col-10">
      <div class="c-row ui-mb-4">
        <div class="c-col-full">
          <div class="c-text-h3">
            Cadastrar senha
          </div>
        </div>
        <div class="c-col-full ui-mt-2">
          <div class="c-text-h4">
            Para definir a senha pela primeira vez é necessário confirmar seu email, o mesmo cadastrado na rede social usada para entrar.
          </div>
        </div>

        <separator />

        <div class="c-col-full ui-mt-4 c-d-flex">
          <c-button
            type="button"
            @click="sendNewPasswordEmail"
            :loading="sendingNewPasswordEmail"
            :disabled="askedToSendNewPasswordEmail"
            class="ui-mr-4"
          >
            Enviar email para definir senha
          </c-button>

          <router-link :to="{ name: 'meus-dados' }">
            <c-button outline>
              Voltar
            </c-button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { CButton, Icon } from '@estrategiahq/coruja-web-ui';
import PForm from '@/components/p-form';
import InputPassword from '@/components/input-password';
import Separator from '@/components/separator';
import { incorrectCurrentPasswordError } from '@/utils/errors';
import GenericModal from '@/modals/generic';
import { UserStoreKeys } from '@/store/modules/user';
import { ValidationProvider } from 'vee-validate';
import { User } from '@/models/user';
import { GlobalStoreKeys } from '@/store/modules/global';

export default {
  data: () => ({
    user: new User(),
    form: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    errors: [],
    askedToSendNewPasswordEmail: false,
    sendingNewPasswordEmail: false,
  }),
  components: {
    CButton,
    PForm,
    InputPassword,
    Separator,
    Icon,
    ValidationProvider,
  },
  computed: {
    ...mapGetters({
      isSubmitting: UserStoreKeys.GET_REQUEST_LOADING,
    }),
  },
  async created() {
    this.$store.commit(GlobalStoreKeys.SET_PAGE_LOADING, true);
    this.user = await this.$services.accounts.getLoggedUser(['has_password']);
    this.$store.commit(GlobalStoreKeys.SET_PAGE_LOADING, false);
  },
  methods: {
    async sendNewPasswordEmail() {
      this.askedToSendNewPasswordEmail = true;

      try {
        this.sendingNewPasswordEmail = true;

        const location = `${window.location.origin}/recuperar-senha`;
        await this.$services.accounts.sendResetPasswordEmail(this.user.email, location);

        this.$modal.show({
          component: GenericModal,
          props: {
            title: 'Email enviado',
            subTitle: 'Você receberá um link para cadastrar a senha.',
            type: 'success',
            confirmButton: {
              label: 'Entendi',
              onClick: () => {
                this.$modal.hide();
              },
            },
          },
        });
      } finally {
        this.sendingNewPasswordEmail = false;
      }
    },
    async onSubmit() {
      try {
        const payload = {
          oldPassword: this.form.currentPassword,
          newPassword: this.form.newPassword,
        };
        await this.$store.dispatch(UserStoreKeys.ACTION_UPDATE_LOGGED_USER_PASSWORD, payload);
        this.showSuccessModal();
        this.$services.events.notifyPasswordChanged();
      } catch (e) {
        this.errors = e.response?.data?.errors || [];

        if (e === incorrectCurrentPasswordError) {
          return this.$toast.show({ type: 'error', text: 'Senha atual incorreta' });
        }
      }
    },
    showSuccessModal() {
      this.$modal.show({
        component: GenericModal,
        props: {
          title: 'Sua senha foi alterada',
          subTitle: 'Você usará esta nova senha para entrar na Área do aluno e no app.',
          type: 'success',
          confirmButton: {
            label: 'Ir para Meus dados',
            onClick: () => {
              this.$modal.hide();
              this.$router.push({ name: 'meus-dados' });
            },
          },
        },
      });
    },
  },
};
</script>

<style>
.text-negative {
  color: var(--c-color-red-500);
}
</style>
